/**
 * This file describes the features that can be associated to a given site role
 */

module.exports = {
    list: [
        {
            name: "SITE_CONFIGURATION",
            isEnabled: false,
            subFeatures: [
                {
                    name: "SITE_INFORMATIONS",
                    isEnabled: false,
                    permissions: {
                        read: false,
                        write: false
                    }
                },
                {
                    name: "BUILDING_AND_FLOOR",
                    isEnabled: false,
                    permissions: {
                        read: false,
                        write: false
                    }
                },
                {
                    name: "GATEWAY",
                    isEnabled: false,
                    permissions: {
                        read: false,
                        write: false
                    }
                },
                {
                    name: "AUTOCALIBRATION_TAG",
                    isEnabled: false,
                    permissions: {
                        read: false,
                        write: false
                    }
                },
                {
                    name: "INDOOR_AREA",
                    isEnabled: false,
                    permissions: {
                        read: false,
                        write: false
                    }
                },
                {
                    name: "GEOFENCE",
                    isEnabled: false,
                    permissions: {
                        read: false,
                        write: false
                    }
                },
                {
                    name: "WALKWAY",
                    isEnabled: false,
                    permissions: {
                        read: false,
                        write: false
                    }
                },
                {
                    name: "WALL",
                    isEnabled: false,
                    permissions: {
                        read: false,
                        write: false
                    }
                },
                {
                    name: "NOTIFICATION",
                    isEnabled: false,
                    permissions: {
                        read: false,
                        write: false
                    }
                },
                {
                    name: "PUSH_BUTTON_ALERT",
                    isEnabled: false,
                    permissions: {
                        read: false,
                        write: false
                    }
                }
            ]
        },
        {
            name: "ASSET_PROVISIONING",
            isEnabled: false,
            subFeatures: [
                {
                    name: "TAG_MANAGEMENT",
                    isEnabled: false,
                    permissions: {
                        read: false,
                        write: false
                    }
                },
                {
                    name: "ASSET_MANAGEMENT",
                    isEnabled: false,
                    permissions: {
                        read: false,
                        write: false
                    }
                },
                {
                    name: "ASSET_CATEGORY_MANAGEMENT",
                    isEnabled: false,
                    permissions: {
                        read: true,
                        write: true
                    }
                }
            ]
        },
        {
            name: "ASSET_SEARCH",
            isEnabled: false,
            subFeatures: [
                {
                    name: "SEARCH",
                    isEnabled: false
                }
            ]
        },
        {
            name: "SITE_PERMISSION",
            isEnabled: false,
            subFeatures: [
                {
                    name: "USER_MANAGEMENT",
                    isEnabled: false
                },
                {
                    name: "ROLE_MANAGEMENT",
                    isEnabled: false
                }
            ]
        },
        {
            name: "GUEST_ACCESS",
            isEnabled: false
        },
        {
            name: "TROUBLESHOOTING",
            isEnabled: false
        },
        {
            name: "REPORTING",
            isEnabled: false
        },
        {
            name: "ANALYTICS",
            isEnabled: false,
            subFeatures: [
                {
                    name: "DASHBOARD",
                    isEnabled: false,
                    permissions: {
                        read: false,
                        write: false
                    }
                },
                {
                    name: "CONTACT_EVENTS",
                    isEnabled: false,
                    permissions: {
                        read: false,
                        write: false
                    }
                }
            ]
        }
    ]
};
