<template>
    <div id="featuresTree" class="baseTemplate"></div>
</template>

<script>
import { mapGetters } from "vuex";
import i18n from "../../i18n";

export default {
    data() {
        return {
            featuresTreeData: [],
            featuresJstree: null
        };
    },
    mounted: function() {
        console.log("Component(featuresTree)::mounted() - Init metronic layout");
        KTLayout.init();
    },
    props: {
        value: {
            type: Array,
            require: true
        },
        pDisableCheckbox: {
            type: Boolean,
            default: false
        }
    },
    watch: {
        // -- Watch -- Property to perform asynchronous or expensive operations in response to changing component "data".
        // --
        value: function(value) {
            console.log("Component(featuresTree)::watch value - called with", value);

            this.initJstree(value);
        },

        // Watch the changed of user language
        user: function(user) {
            console.log("Component(featuresTree)::watch(user) - called with ", user);
            if (user && this.featuresJstree && this.value) {
                if (typeof this.featuresJstree.destroy === "function") {
                    this.featuresJstree.destroy();
                    this.featuresJstree = null;
                }
                this.initJstree(this.value);
            }
        }
    },
    computed: {
        // -- Computed -- Property will only re-evaluate when some of its reactive dependencies have changed.
        // To compose new data from existing datasource
        // To reference a value from the template (cached value)
        // --
        ...mapGetters(["user"])
    },
    methods: {
        // -- Methods -- Property to perform an action.
        // To react on some event happening in the DOM (called every time an event occurs even if method returns the same result)
        // To call a function when something happens in your component (from property "computed" or "watch")
        // ---

        initJstree(value) {
            var self = this;

            // Nothing to do if model is empty
            if (!value || value.length === 0) {
                return;
            }

            // Generate data to inject in jstree, from the array of available features
            this.generateFeaturesTreeData(value);

            this.featuresJstree = $("#featuresTree").jstree({
                plugins: ["wholerow", "checkbox", "types"],
                core: {
                    themes: {
                        responsive: false
                    },
                    data: this.featuresTreeData
                },
                types: {
                    default: {
                        icon: "fa fa-folder kt-font-warning"
                    },
                    file: {
                        icon: "fa fa-file  kt-font-warning"
                    }
                }
            });
            // Update the model form the jstree on UI update
            $("#featuresTree").on("changed.jstree", (e, data) => {
                // Nothing to do, it's not an update of a node
                if (data.action === "model" || data.action === "ready") {
                    console.log("Component(featuresTree)::event changed.jstree - action " +  data.action + " - value:", value);
                    return;
                }

                let featureName = null;
                let subFeatureName = null;

                if (data.node && data.node.original) {
                    featureName = data.node.original.feature ? data.node.original.feature : null;
                    subFeatureName = data.node.original.subFeature ? data.node.original.subFeature : null;
                }

                for (let feature of value) {
                    // Get the feature which has been updated in the model
                    if (feature.name === featureName) {
                        // In this case, the user has select/unselect a feature
                        if (!subFeatureName) {
                            // Set the isDefault to true is selected
                            feature.isEnabled = data.action === "select_node";
                            // Set the isDefault to true for all sub features of the feature
                            if (feature.subFeatures) {
                                for (let subFeature of feature.subFeatures) {
                                    subFeature.isEnabled = data.action === "select_node";
                                }
                            }
                        }

                        // In this case, the user has select/unselect a sub feature
                        else {
                            if (feature.subFeatures) {
                                for (let subFeature of feature.subFeatures) {
                                    // Get the sub feature which has been updated in the model
                                    if (subFeature.name === subFeatureName) {
                                        // Set the isDefault to true is selected
                                        subFeature.isEnabled = data.action === "select_node";
                                    }
                                }

                                // A sub feature has been selected so the feature is set to selected too
                                if (data.action === "select_node") {
                                    feature.isEnabled = true;
                                } else {
                                    // A sub feature has been deselected so the feature is set to selected if there is at least one sub feature selected
                                    let node_info = $("#featuresTree").jstree("get_node", data.node.parent);

                                    let isNodeParentHasAtLeastOneChildSelected = false;
                                    for (let childId of node_info.children) {
                                        let child_info = $("#featuresTree").jstree("get_node", childId);
                                        if (child_info.id !== data.node.id && child_info.state.selected === true) {
                                            isNodeParentHasAtLeastOneChildSelected = true;
                                        }
                                    }
                                    feature.isEnabled = isNodeParentHasAtLeastOneChildSelected;
                                }
                            }
                        }
                        self.$emit("input", value);
                        console.log("Component(featuresTree)::event changed.jstree - action " +  data.action + " - value:", value);
                    }
                }
            });
            if (this.pDisableCheckbox) {
                $("#featuresTree").off("click");
            }
        },

        // Update the UI JSTree from model
        generateFeaturesTreeData(features) {
            this.featuresTreeData = [];

            for (let feature of features) {
                let featureTree = {};
                featureTree.text = i18n.t("ROLE_FEATURES_" + feature.name);
                featureTree.feature = feature.name;

                if (feature.subFeatures) {
                    featureTree.state = { opened: true, checkbox_disabled: this.pDisableCheckbox };
                    featureTree.feature = feature.name;

                    featureTree.children = [];

                    for (let subFeature of feature.subFeatures) {
                        let subFeatureTree = {};
                        subFeatureTree.text = i18n.t("ROLE_FEATURES_" + subFeature.name);

                        subFeatureTree.feature = feature.name;
                        subFeatureTree.subFeature = subFeature.name;
                        if (subFeature.isEnabled) {
                            subFeatureTree.state = { selected: true, checkbox_disabled: this.pDisableCheckbox };
                        }

                        featureTree.children.push(subFeatureTree);
                    }
                } else {
                    if (feature.isEnabled) {
                        featureTree.state = { selected: true, checkbox_disabled: this.pDisableCheckbox };
                    }
                }
                this.featuresTreeData.push(featureTree);
            }
        }
    }
};
</script>

<style scoped>
.baseTemplate {
    display: contents;
}
</style>
